import { type AISummaryStreamingConfig } from './AISummaryStreamingConfigs';
import { BASE_STREAM_URL, CHAT_PATH, CHAT_STREAM_PATH } from './Constants';

export function mapToURL(
	aiSummaryStreamingConfig: AISummaryStreamingConfig,
	streaming = true,
): string {
	const { baseUrl } = aiSummaryStreamingConfig;

	const baseStreamURL = baseUrl ?? BASE_STREAM_URL;

	const chatPath = streaming ? CHAT_STREAM_PATH : CHAT_PATH;

	const requestUrl = `${baseStreamURL}/${chatPath}`;

	return requestUrl;
}
