import type { FollowUpObject, StreamError } from '../api';

export enum AISummaryStreamingState {
	Initialization = 'Initialization',
	AnswerPart = 'AnswerPart',
	FinalResponse = 'FinalResponse',
	FollowUp = 'FollowUp',
	Error = 'Error',
}

export type AISummaryState =
	| {
			state: AISummaryStreamingState.Error;
			content: string;
			answerChunkIndex?: number;
			error: StreamError;
			followUpContent?: FollowUpObject[];
	  }
	| {
			state: AISummaryStreamingState.AnswerPart;
			content: string;
			answerChunkIndex: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
	  }
	| {
			state: AISummaryStreamingState.FinalResponse;
			content: string;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
	  }
	| {
			state: AISummaryStreamingState.Initialization;
			content: string;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
	  }
	| {
			state: AISummaryStreamingState.FollowUp;
			content: string;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent: FollowUpObject[];
	  };
