import { of } from 'rxjs/observable/of';
import { concatMap } from 'rxjs/operators/concatMap';
import { delay } from 'rxjs/operators/delay';
import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import { switchMap } from 'rxjs/operators/switchMap';
import { tap } from 'rxjs/operators/tap';
import { timeInterval } from 'rxjs/operators/timeInterval';

import {
	type AISummaryStreamingConfig,
	makeAIAgentStreamRequest,
	mapToAIAgentRequest,
	mapToURL,
} from '../../../api';
import { type AISummaryState, AISummaryStreamingState } from '../../../state';
import { useObservable, useObservableState } from '../../observable-hooks';

const memoAISummaryStreamingState = (
	aiSummaryConfigPrev: AISummaryStreamingConfig,
	aiSummaryConfigPrevNext: AISummaryStreamingConfig,
) => {
	if ('ari' in aiSummaryConfigPrev && 'ari' in aiSummaryConfigPrevNext) {
		return aiSummaryConfigPrev.ari === aiSummaryConfigPrevNext.ari;
	}
	if ('url' in aiSummaryConfigPrev && 'url' in aiSummaryConfigPrevNext) {
		return aiSummaryConfigPrev.url === aiSummaryConfigPrevNext.url;
	}
	return false;
};

export function useAISummaryStreaming(
	config: AISummaryStreamingConfig & { withRefetch: true },
	initialState?: AISummaryState,
): [AISummaryState, (input: AISummaryState) => void];
export function useAISummaryStreaming(
	config: AISummaryStreamingConfig & { withRefetch?: false },
	initialState?: AISummaryState,
): AISummaryState;

export function useAISummaryStreaming(
	config: AISummaryStreamingConfig,
	initialState: AISummaryState = {
		content: '',
		state: AISummaryStreamingState.Initialization,
	},
): AISummaryState | [AISummaryState, (input: AISummaryState) => void] {
	const { onStart, onStreamStart, onProgress, onError, onDone, delayChunkUpdate, withRefetch } =
		config;

	const aiSummaryStreamingState$ = useObservable(
		(inputs$) =>
			inputs$.pipe(
				distinctUntilChanged(([aiSummaryConfigPrev], [aiSummaryConfigPrevNext]) =>
					memoAISummaryStreamingState(aiSummaryConfigPrev, aiSummaryConfigPrevNext),
				),
				map(([aiSummaryConfig, initialState]) => ({
					aiSummaryConfig,
					initialState,
					requestUrl: mapToURL(aiSummaryConfig),
				})),
				map(({ aiSummaryConfig, initialState, requestUrl }) => ({
					requestOptions: mapToAIAgentRequest(aiSummaryConfig, requestUrl),
					initialState,
				})),
				switchMap(({ requestOptions, initialState }) => {
					onStart?.();
					return makeAIAgentStreamRequest(requestOptions, initialState);
				}),
				timeInterval(),
				concatMap((nextState) => {
					if (!delayChunkUpdate) {
						return of(nextState.value);
					}

					const { time, gap } = delayChunkUpdate;

					if (nextState.interval < gap) {
						return of(nextState.value).pipe(delay(time));
					}
					return of(nextState.value);
				}),
				tap(({ state, answerChunkIndex, error }) => {
					switch (state) {
						case AISummaryStreamingState.AnswerPart: {
							if (answerChunkIndex === 0) {
								onStreamStart?.();
							}
							onProgress?.();
							break;
						}
						case AISummaryStreamingState.FinalResponse: {
							onDone?.();
							break;
						}
						case AISummaryStreamingState.Error: {
							onError?.(error);
						}
					}
				}),
			),
		[config, initialState],
	);

	const [state, refetch] = useObservableState(
		(aiSummarySubject$) =>
			aiSummarySubject$.pipe(
				startWith(initialState),
				switchMap(() => aiSummaryStreamingState$),
			),
		{ ...initialState },
	);

	if (withRefetch) {
		return [state, refetch];
	}

	return state;
}
