import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { type AISummaryState, AISummaryStreamingState } from '../state';

import type { AIAgentRequestConfig } from './AIAgentRequestConfig';
import { readStream } from './readStream';

export const makeAIAgentStreamRequest = (
	{ requestUrl, requestOptions, featureFlags = {} }: AIAgentRequestConfig,
	initialState: AISummaryState,
): BehaviorSubject<AISummaryState> => {
	const behaviorSubject = new BehaviorSubject<AISummaryState>({
		...initialState,
	});

	fetch(requestUrl, {
		credentials: 'include',
		...requestOptions,
	})
		.then((response) => readStream(response))
		.then(async (stream) => {
			let bufferContent = '';
			let answerChunkIndex = 0;
			for await (const chunk of stream) {
				const state = behaviorSubject.getValue();
				switch (chunk.type) {
					case 'ANSWER_PART':
						bufferContent += chunk.message.content;
						behaviorSubject.next({
							...state,
							state: AISummaryStreamingState.AnswerPart,
							content: bufferContent,
							answerChunkIndex,
						});
						answerChunkIndex += 1;
						break;
					case 'FINAL_RESPONSE':
						bufferContent = chunk.message.message.content;
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							state: AISummaryStreamingState.FinalResponse,
							content: bufferContent,
						});
						break;
					case 'FOLLOW_UP_QUERIES':
						if (!featureFlags?.showFollowUps) {
							return;
						}

						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							followUpContent: chunk.message.follow_ups ?? [],
							state: AISummaryStreamingState.FollowUp,
						});
						break;
					case 'ERROR': {
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							state: AISummaryStreamingState.Error,
							error: { ...chunk },
						});
						break;
					}
				}
			}
		})
		.finally(() => {
			behaviorSubject.complete();
		});

	return behaviorSubject;
};
